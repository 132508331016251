import React from 'react';
import styled from 'styled-components';

import Layout from '../layout/Layout';
import Illustration from '../icons/IllustrationRocket';

const Styled = styled.article`
  position: relative;
  max-width: 60rem;
  margin: 12rem auto;
  text-align: center;
  padding: 0 3rem;
  h2 {
    margin: 1rem 0;
  }
`;

const WaitingList = () => (
  <Layout>
    <Styled>
      <Illustration width="300px" height="250px" />
      <h2>Thanks for your interest in Midtype!</h2>
      <p>
        We've placed you on a the waiting list for a new Midtype account. We're
        sending out invites to new users every day! We sincerely appreciate your
        support.
      </p>
    </Styled>
  </Layout>
);

export default WaitingList;
